'use strict';

import svgInject from 'static/js/helpers/svgInject';
import cursor from 'components/controls/cursor/cursor';
import core from 'components/controls/core/core';
import magnetize from 'components/controls/magnetize/magnetize';
import toggle from 'components/controls/toggle/toggle';
import gallery from 'components/blocks/gallery/gallery';

svgInject();
cursor();
core();
magnetize();
toggle();
gallery();

$(function () {
	if ($('.compare-images').length > 0) {
		$('.compare-images').imagesCompare({
			interactionMode: 'mousemove'
		});
	}

	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
});
