/* global SplitText TimelineLite Expo */
import $ from 'jquery';
import 'gsap/all';
import skrollr from 'skrollr';

export default function (maindelay = 0) {
	var animateSplitText = function (text, delay = 0) {
		new TimelineLite({
			perspective: 5000,
			force3D: true,
			transformStyle: 'preserve-3d',
		})
		.delay(delay + maindelay)
		.staggerFrom(text, 1, {
			opacity: 0,
			y: 50,
			rotationX: -100,
			rotationZ: 20,
			transformOrigin: '0% 50% -60',
			ease: Expo.easeOut
		}, 0.1, '+=0');
	};

	var animateBlocks = function (block, delay = 0) {
		new TimelineLite({
			perspective: 5000,
			force3D: true,
			transformStyle: 'preserve-3d',
		})
		.delay(delay + maindelay)
		.from(block, 1, {
			opacity: 0,
			y: 150,
			ease: Expo.easeOut
		}, 0.1, '+=0');
	};

	if ($('.mainpage').length > 0) {
		animateSplitText(
			new SplitText($('.mainpage__name')[0], {
				type: 'chars,words'
			}).chars,
			0
		);
		animateSplitText(
			new SplitText($('.mainpage__text')[0], {
				type: 'words'
			}).words,
			1
		);
	}

	$('.block__membership-text')
		.attr('data-bottom-top', 'transform: translate3d(0rem, 0rem, 0rem)')
		.attr('data-top-bottom', 'transform: translate3d(-100rem, 0rem, 0rem)');

	var s = skrollr.init({
		smoothScrolling: false
	});

	s.refresh();
	if (s.isMobile()) {
		s.destroy();
	}

	if ($('.blog').length > 0) {
		animateBlocks($('.blog')[0], 0.5);
	}

	if ($('.section__title .about').length > 0) {
		if ($('.section__title .title').length > 0) {
			animateSplitText(
				new SplitText($('.section__title .title')[0], {
					type: 'chars,words'
				}).chars,
				0
			);
		}
		if ($('.section__title .subtitle').length > 0) {
			animateSplitText(
				new SplitText($('.section__title .subtitle')[0], {
					type: 'words'
				}).words,
				1
			);
		}
	}

	if ($('.section__block').length > 0) {
		$('.section__block .container').each(function () {
			var o = $(this);
			animateBlocks($(o)[0], 0.5);
		});
	}
}
