import $ from 'jquery';
const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;
const targetElement = document.querySelector('nav');

export default function () {
	function toggleMenu() {
		$('.toggle').click(function () {
			$(this).toggleClass('is-open');
			$('nav').toggleClass('is-open');

			if ($('nav').hasClass('is-open')) {
				disableBodyScroll(targetElement);
			} else {
				enableBodyScroll(targetElement);
			}
		});
	}

	toggleMenu();
}
