import $ from 'jquery';
import Swiper from 'swiper/dist/js/swiper.min.js';

export default function () {
	function gallery() {
		var swiper = new Swiper('.gallery .swiper-container', {
			slidesPerView: 'auto',
			spaceBetween: 30,
			freeMode: true
		});

		var swiper2 = new Swiper('.photos .swiper-container', {
			slidesPerView: 1,
			spaceBetween: 30,
			pagination: {
				el: '.photos .swiper-pagination',
			},
			navigation: {
				nextEl: '.photos .swiper-button-next',
				prevEl: '.photos .swiper-button-prev',
			},
		});
	}

	gallery();
}
