import $ from 'jquery';
import TweenMax from 'gsap/TweenMax';

export default function () {
	function calculateDistance(e, t, n) {
		return Math.floor(Math.sqrt(Math.pow(t - (e.offset().left + e.width() / 2), 2) + Math.pow(n - (e.offset().top + e.height() / 2), 2)));
	}

	function magnetize(element, event) {
		var n = event.pageX,
			i = event.pageY,
			r = $(element),
			o = 20 * r.data('dist') || 120,
			s = r.offset().left + r.width() / 2,
			a = r.offset().top + r.height() / 2,
			l = -0.45 * Math.floor(s - n),
			c = -0.45 * Math.floor(a - i);

		if (calculateDistance(r, n, i) < o) {
			TweenMax.to(r, 0.3, {
				y: c,
				x: l
			});
			// r.css({
			// 	transform: 'matrix(1, 0, 0, 1, ' + l + ', ' + c + ')'
			// });
			r.addClass('magnet');
		} else {
			TweenMax.to(r, 0.45, {
				y: 0,
				x: 0
			});
			// r.css({
			// 	transform: 'matrix(1, 0, 0, 1, 0, 0)'
			// });
			r.removeClass('magnet');
		}
	}

	function magnetizeElements() {
		$(document).on('mousemove touch', function (event) {
			if ($(window).width() > 768 && !window.isMobile) {
				$('.magnetize').each(function () {
					magnetize($(this), event);
				});
			}
		});
	}

	magnetizeElements();
}
