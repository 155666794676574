import $ from 'jquery';

export default function () {
	function detectMobile() {
		if (navigator.userAgent.match(/Android/i)
		|| navigator.userAgent.match(/webOS/i)
		|| navigator.userAgent.match(/iPhone/i)
		|| navigator.userAgent.match(/iPad/i)
		|| navigator.userAgent.match(/iPod/i)
		|| navigator.userAgent.match(/BlackBerry/i)
		|| navigator.userAgent.match(/Windows Phone/i)
		) {
			return true;
		} else {
			return false;
		}
	}

	window.isMobile = detectMobile();

	function cursorMoveFunc() {
		var element = $('#cursor');

		$('body').mouseover(function () {
			$(this).css({cursor: 'none'});
		});
		$('html, body, .page, a, input, video').css('cursor', 'none');

		function activeMoveFunc(event) {
			function removeLabel() {
				element.find('.cursor__label').text('');
			}

			element.css({
				left: event.clientX - element.width() / 2,
				top: event.clientY - element.height() / 2
			});

			if ($(event.target).data('cursor-type') === 'medium') {
				element.removeClass().addClass('is-medium');
				removeLabel();
			} else {
				if ($(event.target).data('cursor-type') === 'large') {
					element.removeClass().addClass('is-large');
					element.find('.cursor__label').text($(event.target).data('cursor-text'));
				} else {
					if ($(event.target).data('cursor-type') === 'instagram') {
						element.removeClass().addClass('is-instagram');
					} else {
						element.removeClass();
					}
				}
			}
		}

		var moveFunc = function () {
			if (window.isMobile) {
				$(window).off('mousemove', activeMoveFunc);
				element.remove();
			} else {
				$(window).on('mousemove', activeMoveFunc);
			}
		};

		moveFunc();
		$(window).resize(moveFunc);

		// "medium" == $(t.target).data("cursor-type") ? (e.removeClass().addClass("is-medium"), n()) :
		// "big" == $(t.target).data("cursor-type") ? "btn-play" == $(t.target).data("cursor-text") ? (e.removeClass().addClass("is-play").addClass("is-big"), n()) : "btn-pause" == $(t.target).data("cursor-text") ? (e.removeClass().addClass("is-pause").addClass("is-big"), n()) : (e.removeClass().addClass("is-view").addClass("is-big"), e.find(".cursor__label").text($(t.target).data("cursor-text"))) : (e.removeClass(), n())
	}
	if (!window.isMobile) {
		cursorMoveFunc();
	}
}
